import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import ApplicationsDashboardPage from "../../components/DashboardPage/index-applications"
import DashboardSavedJobs from "../../components/DashboardPage/DashboardSavedJobs"

const DashboardSaved = () => (
  <Layout>
    <SEO title="Saved Jobs" />
    <ApplicationsDashboardPage def="2">
      <DashboardSavedJobs />
    </ApplicationsDashboardPage>
  </Layout>
)

export default DashboardSaved
