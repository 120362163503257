import styled from "styled-components"

const DashboardSavedJobs = styled.div`
  margin-bottom: 10rem;
  .no__job {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 2.375rem;
    .no__activity__image,
    .no__activity__texts {
      margin: 0 auto;
    }
    .no__activity__image {
      width: 15.625rem;
      height: auto;
      margin-bottom: 1.5rem;
    }
    .no__activity__text {
      text-align: center;
      line-height: 140%;
      a {
        color: #cec46a;
      }
    }
  }
  .job {
    padding-bottom: calc(1.5rem);
  }
  @media (max-width: 768px) {
    .no__job {
      .no__activity__image {
        width: 13rem;
      }
    }
  }
`

export default DashboardSavedJobs
