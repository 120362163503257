// modules imports
import React from "react"
import { Link } from "gatsby"
import { useSelector } from "react-redux"

// component imports
import DashboardSavedJobsCSS from "./style"

// redux
import { selectSavedJobs } from "../../../redux/slices/jobs"

// utils
import { renderJobButton } from "../utils"

// images
import Image from "../../image"

const DashboardSavedJobs = () => {
  const savedJobs = useSelector(selectSavedJobs)

  return (
    <DashboardSavedJobsCSS>
      {savedJobs.length > 0 ? (
        savedJobs.map((saveJob, index) => (
          <div className="job" key={index}>
            {renderJobButton(saveJob, index, "saved")}
          </div>
        ))
      ) : (
        <div className="no__job">
          <Image
            className="no__activity__image"
            imgName="no_activity.png"
            alt="no_activity"
          />
          <div className="no__activity__texts">
            <p className="no__activity__text">
              Oops, there are no activities yet!
            </p>
            <p className="no__activity__text">
              Check out awesome jobs <Link to="/job-fair">here</Link>
            </p>
          </div>
        </div>
      )}
    </DashboardSavedJobsCSS>
  )
}

export default DashboardSavedJobs
